import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@vwfs-bronson/bronson-react';
import { getIsAuthenticated } from '../actions/authAction';
import { useAuth } from '../hooks/useAuth';
import { setSelectedProductFamily } from '../actions/summaryAction';
import { FAMILY_PRODUCT } from '../types/types';
import {
  setCalculatorType,
  setMaxOffersToSend,
  setHasAccessToLongDrive,
  setReturnSalesassistInfo,
  setIsSalesAssist,
} from '../actions/globalAction';
import { addNotificationModal } from '../actions/notificationAction';
import { parseJWT } from '../helpers/parseJWT';

function AuthPage() {
  const { isAuthenticated, error } = useAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      dispatch(getIsAuthenticated(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    localStorage.removeItem('VWFS_numOffers');
    localStorage.removeItem('VWFS_simulatorType');
    localStorage.removeItem('VWFS_HasAccessToLongDrive');
    localStorage.removeItem('VWFS_isSalesassist');
  }, []);

  useEffect(() => {
    if (error) navigate('/error');
    if (isAuthenticated) {
      var tokenJWT = JSON.parse(window.atob(token));

      const jwt = parseJWT(tokenJWT.AccessToken);

      if (jwt.data) {
        const jwtData = JSON.parse(jwt.data);
        if (jwtData.Path === '/dashboard') {
          navigate('/dashboard');
          return;
        }
        localStorage.setItem(
          'VWFS_numOffers',
          jwtData.NumOffers ? jwtData.NumOffers : 1,
        );
        localStorage.setItem(
          'VWFS_simulatorType',
          jwtData.Type ? jwtData.Type : 1,
        );
        localStorage.setItem(
          'VWFS_HasAccessToLongDrive',
          jwtData.HasAccessToStandAlone ? jwtData.HasAccessToStandAlone : false,
        );
        localStorage.setItem(
          'VWFS_isSalesassist',
          jwtData.IsSalesassist ? jwtData.IsSalesassist : false,
        );

        dispatch(setCalculatorType(jwtData.Type ?? 1));

        let selectedProductFamily = FAMILY_PRODUCT.RENTING;

        switch (jwtData.Type) {
          case 3:
            selectedProductFamily = FAMILY_PRODUCT.FINANCING;
            break;
          case 7:
            selectedProductFamily = FAMILY_PRODUCT.LEASING;
            break;
        }

        dispatch(setSelectedProductFamily(selectedProductFamily));
        dispatch(
          setHasAccessToLongDrive(
            jwtData.HasAccessToStandAlone
              ? jwtData.HasAccessToStandAlone
              : false,
          ),
        );
        if (jwtData.returnSalesassistInfo) {
          const returnSalesassistInfo = {
            client_id: jwtData.returnSalesassistInfo.client_id,
            client_secret: jwtData.returnSalesassistInfo.client_secret,
            returnUrl: atob(jwtData.returnSalesassistInfo?.returnUrl),
          };
          dispatch(setReturnSalesassistInfo(returnSalesassistInfo));
        }

        if (jwtData.EmiAlert === 1) {
          dispatch(
            addNotificationModal(
              'Las emisiones no coinciden con el IEM incorporado, por favor, revisa la configuración',
              'Error emisiones',
            ),
          );
          // dispatch(addNotificationAlert("Test msg", "Test", TYPE_NOTIFICACION.ALERT));
        }
        dispatch(setMaxOffersToSend(jwtData.NumOffers ?? 1));
        dispatch(setIsSalesAssist(jwtData.IsSalesassist ?? false));
        navigate(`/${jwtData.Path ?? '/simulator'}`);
      } else {
        navigate('/error');
      }
    }
  }, [error, isAuthenticated, navigate, dispatch, token]);

  return <Spinner fullPage text={'Espere mientras estamos validando'} />;
}

export default AuthPage;

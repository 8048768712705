// import QuotationService from "../services/QuotationService";
import { adapterMantenimientoLD } from '../adapters/adapterMantenimientoLD';
import AdditionalProductsService from '../services/AdditionalProductsService';
import { types } from '../types/types';
import { addNotificationError } from './notificationAction';

export const setSelectedProductFamily = (value) => ({
  type: types.summarySetSelectedProductFamily,
  payload: value,
});

export const setSelectedToken = (payload) => ({
  type: types.summarySetSelectedToken,
  payload,
});

export const setSelectedPlan = (payload) => ({
  type: types.summarySetSelectedPlan,
  payload,
});

export const setSelectedProduct = (payload) => ({
  type: types.summarySetSelectedProduct,
  payload,
});

export const setSelectedCampain = (payload) => ({
  type: types.summarySetSelectedCampain,
  payload,
});

export const setSelectedMeses = (payload) => ({
  type: types.summarySetSelectedMeses,
  payload,
});

export const setSelectedKms = (payload) => ({
  type: types.summarySetSelectedKms,
  payload,
});

export const setSelectedPrecio = (payload) => ({
  type: types.summarySetSelectedPrecio,
  payload,
});

export const setSelectedIsInitAmount = (payload) => ({
  type: types.summarySetSelectedIsInitAmount,
  payload,
});

export const setSelectedServices = (payload) => ({
  type: types.summarySetSelectedServices,
  payload,
});

export const setSelectedTires = (payload) => ({
  type: types.summarySetSelectedTires,
  payload,
});

export const setSelectedAdditionalProductsData = (payload) => ({
  type: types.summarySetSelectedAdditionalProductsData,
  payload,
});

export const setSelectedResumeOffers = (payload) => ({
  type: types.summarySetSelectedResumeOffers,
  payload,
});

export const setSelectedMantenimentoStandAlone = (payload) => ({
  type: types.summarySetSelectedMantenimentoStandAlone,
  payload,
});
export const setSelectedSeguroAutoStandAlone = (payload) => ({
  type: types.summarySetSelectedSeguroAutoStandAlone,
  payload,
});
export const setSelectedClientStandAlone = (payload) => ({
  type: types.summarySetSelectedClientStandAlone,
  payload,
});
export const setSelectedAdditionalProductMantenimientoLD = (
  payload,
  isOmitir = false,
  IsRemoveHost = false,
) => {
  return async (dispatch, getState) => {
    if (!IsRemoveHost) dispatch(setSelectedAdditionalProductsData(payload));

    if (!isOmitir) {
      var additionalProductsService = new AdditionalProductsService();
      const { status, errors } =
        await additionalProductsService.identifyMantenimientoLD({
          IsBaja:
            payload?.MantenimientoLD === undefined ||
            payload?.MantenimientoLD[0]?.Codigo === undefined
              ? true
              : false,
          ProductoAdicionalSerialized:
            payload?.MantenimientoLD !== undefined &&
            payload?.MantenimientoLD[0]?.Codigo !== undefined
              ? JSON.stringify(
                  adapterMantenimientoLD(
                    payload,
                    getState().addicionalProducts.additionalProducts
                      .MantenimientoLD,
                  ),
                )
              : '',
        });

      if (status !== 200 && !IsRemoveHost) {
        dispatch(addNotificationError(errors));
      }
    }
  };
};

export const setSelectedAdditionalProductSeguroAutomovil = (
  payload,
  isOmitir = false,
  IsRemoveHost = false,
) => {
  return async (dispatch) => {
    dispatch(setSelectedAdditionalProductsData(payload));
    if (!isOmitir) {
      var additionalProductsService = new AdditionalProductsService();
      const { status, errors } =
        await additionalProductsService.identifySeguroAutomovil({
          IsBaja:
            payload?.SegurosAutomovilLD === undefined ||
            payload?.SegurosAutomovilLD[0]?.Modalidad === undefined
              ? true
              : false,
          ProductoAdicionalSerialized:
            payload?.SegurosAutomovilLD !== undefined &&
            payload?.SegurosAutomovilLD[0]?.Modalidad !== undefined
              ? JSON.stringify(payload?.SegurosAutomovilLD[0])
              : '',
        });

      if (status !== 200 && !IsRemoveHost) {
        dispatch(addNotificationError(errors));
      }
    }
  };
};

export const setSelectedAdditionalProductPerdidaTotal = (
  payload,
  isOmitir = false,
  IsRemoveHost = false,
) => {
  return async (dispatch) => {
    dispatch(setSelectedAdditionalProductsData(payload));

    if (!isOmitir) {
      var additionalProductsService = new AdditionalProductsService();
      const { status, errors } =
        await additionalProductsService.identifyPerdidaTotal({
          IsBaja:
            payload?.PerdidaTotalServ === undefined ||
            payload?.PerdidaTotalServ[0]?.Codigo === undefined
              ? true
              : false,
        });

      if (status !== 200 && !IsRemoveHost) {
        dispatch(addNotificationError(errors));
      }
    }
  };
};

export const setSelectedAdditionalProductPagoProtegido = (
  payload,
  isOmitir = false,
  IsRemoveHost = false,
) => {
  return async (dispatch) => {
    dispatch(setSelectedAdditionalProductsData(payload));
    if (!isOmitir) {
      var additionalProductsService = new AdditionalProductsService();
      const { status, errors } =
        await additionalProductsService.identifyPagoProtegido({
          IsBaja:
            payload?.PagosProtegidosServ === undefined ||
            payload?.PagosProtegidosServ[0]?.Codigo === undefined
              ? true
              : false,
          ProductoAdicionalSerialized:
            payload?.PagosProtegidosServ !== undefined &&
            payload?.PagosProtegidosServ[0]?.Codigo !== undefined
              ? JSON.stringify({
                  TipoSeguroCredito: payload.PagosProtegidosServ[0]?.Codigo,
                  NumTitulares: payload.PagosProtegidosServ[0]?.NumConductores,
                })
              : '',
        });

      if (status !== 200 && !IsRemoveHost) {
        dispatch(addNotificationError(errors));
      }
    }
  };
};

export const setSelectedAdditionalProductRetiradaCarnet = (
  payload,
  isOmitir = false,
  IsRemoveHost = false,
) => {
  return async (dispatch) => {
    dispatch(setSelectedAdditionalProductsData(payload));
    if (!isOmitir) {
      var additionalProductsService = new AdditionalProductsService();
      const { status, errors } =
        await additionalProductsService.identifyRetiradaCarnet({
          IsBaja:
            payload?.RetiradaCarneServ === undefined ||
            payload?.RetiradaCarneServ[0]?.Codigo === undefined
              ? true
              : false,
        });

      if (status !== 200 && !IsRemoveHost) {
        dispatch(addNotificationError(errors));
      }
    }
  };
};

export const resetData = () => ({
  type: types.summaryResetData,
});

import { Layout, Table, FormSection } from '@vwfs-bronson/bronson-react';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirstLetter';
import { financialFormat } from '../../../../helpers/financialFormat';
import { useSummary } from '../../../../hooks/useSummary';
import { useOffer } from '../../../../hooks/useOffer';
import { formatWithThousandsSeparator } from '../../../../helpers/formatWithThousandsSeparator';

export const RentingOfferResume = () => {
  const { selectedResumeOffers } = useSummary();
  const { isSimulatorOfferSended } = useOffer();
  const offers = selectedResumeOffers.filter((x) => x.OfferType?.Key == 'RT');

  if (offers?.length === 0) return null;

  const getSubtitle = () => {
    if (isSimulatorOfferSended === null || !isSimulatorOfferSended?.IsSended) {
      return <></>;
    }
    if (!isSimulatorOfferSended.ItsDone) {
      return <>No se ha podido generar oferta</>;
    }
    var offersId = '';
    isSimulatorOfferSended.OffersId?.forEach((item) => {
      offersId += item + ',';
    });
    return <>{offersId.substring(0, offersId.length - 1)}</>;
  };

  return (
    <Layout.Item className="u-mt-none">
      <FormSection
        defaultOpen={
          isSimulatorOfferSended === null || !isSimulatorOfferSended?.IsSended
            ? true
            : false
        }
        subtitle={getSubtitle()}
        className="u-mb-small"
        success={isSimulatorOfferSended?.IsSended}
        error={
          isSimulatorOfferSended !== null && !isSimulatorOfferSended.IsSended
        }
        title="Renting"
      >
        <Table bordered colored>
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              {offers.map((offer) => (
                <Table.Th key={offer.Code} className="u-p-none  u-text-center">
                  {offer.QuoteType.Key === 'EST' ? 'Recomendada' : offer.Name}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody testId="table-head-01">
            <Table.Tr>
              <Table.Th>Producto</Table.Th>
              {offers.map((offer) => (
                <Table.Td key={`Plazo_${offer.Code}`} className="u-text-center">
                  {capitalizeFirstLetter(offer.Product.Value)}
                </Table.Td>
              ))}
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Tarifa</Table.Th>
              {offers.map((offer) => (
                <Table.Td key={`Plazo_${offer.Code}`} className="u-text-center">
                  {offer.Campain.Value}
                </Table.Td>
              ))}
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Plazo</Table.Th>
              {offers.map((offer) => (
                <Table.Td key={`Plazo_${offer.Code}`} className="u-text-center">
                  {offer.Months} meses
                </Table.Td>
              ))}
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Kilometraje anual</Table.Th>
              {offers.map((offer) => (
                <Table.Td
                  key={`KmTotal_${offer.Code}`}
                  className="u-text-center"
                >
                  {offer.Km
                    ? `${formatWithThousandsSeparator(offer.Km, '.')} km`
                    : '-'}
                </Table.Td>
              ))}
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Entrada</Table.Th>
              {offers.map((offer) => (
                <Table.Td
                  key={`Entrada_${offer.Code}`}
                  className="u-text-center"
                >
                  {parseInt(offer.InitAmount) > 0
                    ? `${financialFormat(offer.InitAmount)} €`
                    : 'No'}
                </Table.Td>
              ))}
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Cuota</Table.Th>
              {offers.map((offer) => (
                <Table.Td key={`Cuota_${offer.Code}`} className="u-text-center">
                  {financialFormat(offer.FeeAmount)} €
                </Table.Td>
              ))}
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </FormSection>
    </Layout.Item>
  );
};
